<template>
  <div class="addressee">
    <div class="addressee-header">
      <span class="addressee-title">收件人</span>
      <span class="address">目的地：<span>{{this.whereReceive}}</span></span>
    </div>

    <!-- 收件人组件 -->
    <Address-form :addressArr="addressArr" :serviceLine="serviceLine" :show="show" :gateSign="gateSign" @closeAddress="closeAddress" @importAddress="useAddress" ref="addressForm"></Address-form>

    <!-- 添加收件人按钮 -->
    <div class="add-address" v-if="show"
         @click="addAddress">添加收件人</div>

    <!-- 地址簿 -->
    <div v-if="addressTableVisable" class="address-table-div">
      <el-dialog title="" :visible.sync="addressTableVisable" width="1000px" :modal-append-to-body="false" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
        <!-- 标题 -->
        <div class="title-div">
          <div class="title-text">地址簿</div>
          <div class="title-input" @change="handleSeachChanged">
            <el-input placeholder="搜索姓名和电话" suffix-icon="el-icon-search" v-model="addressSearchContent"></el-input>
          </div>
        </div>
        <!-- 关闭按钮 -->
        <div class="close-btn">
          <i class="el-icon-close address-close posi" @click="addressTableVisable = false"></i>
        </div>
        <!-- 收件人 -->
        <div class="table-div sender-table-div">
          <el-table
          :data="addressRecipientsData"
          style="width: 100%"
          border
          empty-text="暂无搜索结果"
          @row-click="handleRowClicked"
          v-loading="addressLoading"
          >
            <el-table-column prop="name" label="姓名" width="117" :resizable="false">
              <template slot-scope="scope">
                <span v-if="!scope.row.isEidt">{{scope.row.name}}</span>
                <div v-else class="table-input-div">
                  <el-input v-model="scope.row.name"></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="phone" label="电话" width="149" :resizable="false">
              <template slot-scope="scope">
                <span v-if="!scope.row.isEidt">{{scope.row.phone}}</span>
                <div v-else class="table-input-div">
                  <el-input v-model="scope.row.phone"></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="identityNumber" label="身份证" width="216" :resizable="false">
              <template slot-scope="scope">
                <span v-if="!scope.row.isEidt">{{scope.row.identityNumber}}</span>
                <div v-else class="table-input-div">
                  <el-input v-model="scope.row.identityNumber"></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="addressDetail" label="地址" width="383" :resizable="false">
              <template slot-scope="scope">
                <span v-if="!scope.row.isEidt">{{scope.row.province}} {{scope.row.city}} {{scope.row.county}} {{scope.row.province}}</span>
                <div v-else class="addressDetail-div">
                  <div class="addressDetail-select-div">
                    <div style="width: 150px"> 
                      <MyCascader v-model="scope.row.retained" ></MyCascader>
                    </div>
                  </div>
                  <div class="table-input-div addressDetail-input-div">
                    <el-input v-model="scope.row.province"></el-input>
                  </div>
                  <div class="table-input-div addressDetail-input-div">
                    <el-input v-model="scope.row.postCode"></el-input>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" :resizable="false">
              <template slot-scope="scope">
                <div class="edit-div">
                  <!-- <div class="edit-text posi" v-if="!scope.row.isEidt" @click.stop="eidtClicked(scope.$index, scope.row)">编辑</div>
                  <div class="edit-text posi" v-else @click.stop="saveClicked(scope.$index, scope.row)">保存</div>
                  <div class="space-text posi">｜</div> -->
                  <div class="edit-text posi" @click.stop="deletedClicked(scope.$index, scope.row)">删除</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import AddressForm from "./addressForm"
import { deepClone } from '@/utils/common'
import MyCascader from "@components/cascader.vue"
import {listAllConsigneeAddress,delConsigneeAddress} from "@/api/address/consignee/consignee"
export default {
  name: "Addressee",
  components: { AddressForm ,MyCascader},
  props: {
    addressArr:{
      type: Array,
      default: () => []
    },
    show:{
      type: Boolean,
      default: true
    },
    gateSign: {
      type: String,
      default: ""
    },
    serviceLine: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      // 前边填写的目的地以及体积重量
      indentData: {},
      whereReceive:'', // 目的地

      // 收件人空数据
      obj: {
        // 收件人信息  姓名，手机号，身份证号，城市，详细地址
        consignee: {
          name: '', // 收件人姓名
          identityNumber:'', //身份证号
          phone: '', // 收件人手机号
          province: '', // 省
          city: '', // 市
          county: '', // 区
          postCode: '', // 邮编
          addressDetail: '', // 详细地址
          retained: [], // 三级联动值
        },
        // 包裹信息
        packageList: [
          {
            //包裹大小
            length:this.$store.getters.getItemInfo.length,
            width:this.$store.getters.getItemInfo.width,
            height:this.$store.getters.getItemInfo.height,
            weight:this.$store.getters.getItemInfo.weight,
            // 保险服务
            insuranceId: this.$store.state.insurance,
            // 包裹备注
            note: '',
            // 物品
            goodsList: [
              {
                // 物品名称
                id: '',
                // 物品数量
                number: 1,
                // 物品单价
                price: '',
                // 物品税率
                tax:'',
                // 物品重量
                suttle:'',
                // 物品名称(文本)
                name: "",
                // 物品类别
                type: "",
                sku: "",
              }
            ]
          }
        ],
      },

      // 地址簿搜索条件
      addressSearchContent: "",
      // 地址簿-收件人数组信息
      addressRecipientsData: [
        // {
        //   name: '易洋千玺', // 收件人姓名
        //   identityNumber: '342524199412124567', // 身份证号
        //   phone: '13876889647', // 收件人手机号
        //   province: '浙江', // 省
        //   city: '杭州市', // 市
        //   county: '上城区', // 区
        //   postCode: '310002', // 邮编
        //   addressDetail: '文三路123号', // 详细地址
        //   retained: ['zhinan','shejiyuanze','yizhi'], // 三级联动值
        //   isEidt: false,
        // }
      ],
      // 地址簿是否显示
      addressTableVisable: false,
      //选择的第几个收件人index
      addressIndex: 0,
      addressLoading: false
    }
  },
  mounted(){
    let dress = this.$store.getters.getDest
    this.whereReceive = dress
  },
  methods: {
    // 添加收件人
    addAddress () {
      let obj = deepClone(this.obj)
      this.addressArr.push(obj);
      this.$emit("addAddress",this.addressArr.length-1);
    },
    // 删除收件人
    closeAddress(index){
      this.addressArr.splice(index, 1);
    },
    // 获取地址簿联系人
    getAddressData(search){
      this.addressLoading = true
      listAllConsigneeAddress({'nameOrPhone' : search}).then(res=>{
        this.addressLoading = false
        if(res.data && res.data.code == 0 && res.data.data.list){
          this.addressRecipientsData = res.data.data.list;
          this.addressRecipientsData.forEach(item=>{
            item.isEidt = false;
            // item.retained = [item.province,item.city,item.county +" (邮编:"+ item.postCode+")"];
            item.retained = [item.province.trim(),item.city.trim(),item.county.trim()];
          })
        }
      }).catch(err=>{
        console.error(err);
        this.addressLoading = false
      });
    },
    // 使用地址簿
    useAddress(index){
      this.addressTableVisable = true;
      this.addressIndex = index;
      // 获取地址簿信息
      this.getAddressData();
    },
    // 搜索条件发生变化
    handleSeachChanged(){
      console.log("寄件人搜索条件发生变化",this.addressSearchContent);
      this.getAddressData(this.addressSearchContent);
    },
    // 地址簿行被点击选择
    handleRowClicked(arg1){
      console.log("寄件人选择了行",arg1);
      this.$emit("updateAddress",arg1,this.addressIndex);
      this.addressTableVisable = false;
    },
    // 地址簿编辑被点击
    eidtClicked(arg1,arg2){
      console.log("地址簿-寄件人编辑被点击",arg1,arg2);
      arg2.isEidt = true;
    },
    // 地址簿保存被点击
    saveClicked(arg1,arg2){
      console.log("地址簿-寄件人保存被点击",arg1,arg2);
      arg2.isEidt = false;
    },
    // 地址簿删除被点击
    deletedClicked(arg1,arg2){
      console.log("地址簿-寄件人删除被点击",arg1,arg2);
      delConsigneeAddress({'addressId':arg2.id}).then(res=>{
        console.log(res)
        if(res.data && res.data.code == 0){
           this.addressRecipientsData.splice(arg1,1);
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.addressee {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  &::v-deep .el-input__inner {
    border-radius: 8px;
  }
  box-sizing: border-box;
  margin-top: 20px;
  // 添加收件人
  .add-address {
    width: 120px;
    height: 44px;
    background: #6d5efe;
    border-radius: 8px;
    text-align: center;
    line-height: 44px;
    color: #fff;
    margin: 30px auto;
    margin-bottom: 0;
    cursor: pointer;
  }

  // 地址簿
  .address-table-div{
    position: relative;
    ::v-deep .el-dialog__wrapper{
      .el-dialog{
        border-radius: 8px;
        overflow: hidden;
        .el-dialog__header{
          padding: 0px;
        }
        .el-dialog__body{
          padding: 0px 0px;
        }
      }
    }
    .title-div{
      margin: 25px 0px;
      display: flex;
      align-items: center;
      .title-text{
        height: 33px;
        font-size: 24px;
        font-weight: 600;
        color: #1E2B4F;
        line-height: 33px;
        margin-left: 30px;
        margin-right: 30px;
      }
      .title-input{
        ::v-deep .el-input{
          width: 230px;
          .el-input__inner{
            background: #FFFFFF;
            border-radius: 10px;
            border: 1px solid #D2D5DC;
          }
          .el-input__suffix{
            color: #6D5EFE;
            font-size: 18px;
          }
        }
      }
    }
    .close-btn{
      position: absolute;
      top: 20px;
      right: 20px;
      .address-close{
        font-size: 20px;
        color: #6D5EFE;
        line-height: 20px;
      }
    }
    .edit-div{
      display: flex;
      flex-direction: row;
      justify-content: center;
      .edit-text{
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #6D5EFE;
        line-height: 20px;
      }
      .space-text{
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #B1B6C3;
        line-height: 20px;
      }
    }
    .table-input-div{
      ::v-deep .el-input .el-input__inner{
        height: 36px;
        border-radius: 4px;
        padding: 0px 7px;
      } 
    }
    .addressDetail-div{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .addressDetail-select-div{
        ::v-deep .my-cascader[data-v-4edd4d4e] .el-input .el-input__inner{
          border-radius: 4px;
          height: 36px;
        }
      }
      .addressDetail-input-div{
        margin-left: 5px;
      }
    }
  }
}
.addressee-header {
  margin-bottom: 36px;
  // 寄件人
  .addressee-title {
    font-size: 24px;
    font-weight: bold;
    color: #1e2b4f;
    margin-right: 24px;
  }
  .address {
    color: #636d88;
    font-size: 14px;
  }
}
</style>