<template>
  <div class="my-cascader" :style="{'--myPadLeft': label.length + 1 + 'em'}">
    <span class="my-label">{{ label }}</span>
    <el-cascader 
      :placeholder="placeholder"
      v-model="value"
      :options="options"
      filterable
      class="my-cascader-pad"
      @change="handleChange">
    </el-cascader>
  </div>
</template>
<script>

export default {
  name: "my-cascader",
  props: {
    label:{
      type:[String],
      default: ""
    },
    value: {
      type: [Array,String],
      default () {
        return ''
      }
    },
    placeholder:{
      type: [String],
      default: '',
    },
  },
  data () {
    return {
      returnVal:this.value,
      options: this.$store.state.cityOption || []
    }
  },
  created(){
    // this.getOption();
    // this.matching()
  },
  methods: {
    handleChange (value) {
      this.$emit('input', value)
      this.$emit('changeVal', value)
    },
    // 匹配批量导入的三级联动数据
    // matching(){
    //   let tree = this.options
    //   let value = this.value
    //   tree.forEach(item => {
    //     console.log(value, item)
    //     if(item.value === value[0] || item.value.includes(value[0])){
    //       item.children.forEach(two=>{
    //         if(two.value === value[1] || item.value.includes(value[1])){
    //           two.children.forEach(three => {
    //             if(three.value === value[2] || three.value.includes(value[2])){
    //               this.returnVal = [item.value,two.value,three.value]
    //               this.value = this.returnVal
    //               return;
    //             }
    //           })
    //         }
    //       })
    //     }
    //   })
    // },
    // getOption(){
    //   getChinaCityList().then(res=>{
    //     if(res.data && res.data.code == 0 && res.data.data && res.data.data.list){
    //       this.options = res.data.data.list;
    //     }
    //   })
    // }
  }
}
</script>
<style lang="less" scoped>
.my-cascader{
  position: relative;
  &::v-deep .el-input .el-input__inner{
    border-radius: 10px;
    height:44px;
    padding-left: var(--myPadLeft);
  }
  .my-label{
    position:absolute;
    top: 50%;
    transform:translateY(-50%);
    left: 1em;
    color:#1E2B4F;
    font-size: 14px;
    z-index: 10;
  }
  .my-cascader-pad{
    width: 100%;
  }
}
</style>