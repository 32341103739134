<template>
  <div class="my-input" :style="{'--myPadLeft': label.length + 1 + 'em'}">
    <span class="my-label">{{ label }}</span>
    <el-input :placeholder="placeholder"
              :value="value"
              class="my-input-pad"
              @input="handleInput($event)"
              @change="handleChange">
    </el-input>
  </div>
</template>
<script>
export default {
  name: "my-input",
  props: {
    label:{
      type:[String],
      default: ""
    },
    value: {
      type: [String, Number],
      default () {
        return ''
      }
    },
    placeholder:{
      type: [String],
      default: '',
    },
  },
  data () {
    return {}
  },

  methods: {
    handleInput ($event) {
      this.$emit('input', $event)
    },
    handleChange (value) {
      //处理纯空格
      if (Object.prototype.toString.call(value) === '[object String]' && value.trim() === '') {
        this.$emit('input', '')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.my-input{
  position: relative;
  &::v-deep .el-input .el-input__inner{
    border-radius: 10px;
    height:44px;
    padding-left: var(--myPadLeft);
  }
  .my-label{
    position:absolute;
    top: 50%;
    transform:translateY(-50%);
    left: 1em;
    color:#1E2B4F;
    font-size: 14px;
    z-index: 10;
  }
}
</style>