<template>
  <div>
    <div class="package" v-for="(item, i) in packageList" :key="i">
      <!-- 包裹左侧信息 -->
      <div class="package-left">
        <div class="package-number">包裹{{ i + 1 }}</div>
        <div class="package-left-info">
          <div class="package-title margin-bottom-12">服务路线：</div>
          <div class="package-content margin-bottom-20">{{ serviceName }}</div>

          <div class="package-title margin-bottom-12">包裹重量尺寸：</div>
          <el-form
            class="package-content-size"
            :rules="rules"
            :model="packageList[i]"
            ref="sizeForm"
          >
            <div class="size-item">
              <div class="item-unit">长度(cm)</div>
              <el-form-item class="item-value" prop="length">
                <el-input
                  v-model="item.length"
                  :disabled="item.disabled"
                  onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
                ></el-input>
              </el-form-item>
            </div>
            <div class="size-item">
              <div class="item-unit">宽度(cm)</div>
              <el-form-item class="item-value" prop="width">
                <el-input
                  v-model="item.width"
                  :disabled="item.disabled"
                  onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
                ></el-input>
              </el-form-item>
            </div>
            <div class="size-item">
              <div class="item-unit">高度(cm)</div>
              <el-form-item class="item-value" prop="height">
                <el-input
                  v-model="item.height"
                  :disabled="item.disabled"
                  onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
                ></el-input>
              </el-form-item>
            </div>
            <div class="size-item">
              <div class="item-unit">重量(kg)</div>
              <el-form-item class="item-value" prop="weight">
                <el-input
                  v-model="item.weight"
                  :disabled="item.disabled"
                  onkeyup="this.value = this.value.replace(/[^\d^\\.]+/g, '').replace(/^0+(\d)/, '$1').replace(/^\./, '0.').match(/^\d*(\.?\d{0,2})/g)[0] || '';"
                ></el-input>
              </el-form-item>
            </div>
          </el-form>

          <div class="package-title margin-bottom-12">保险服务：</div>
          <div class="margin-bottom-20">
            <el-select
              v-model="item.insuranceId"
              placeholder="请选择"
              popper-class="my-select"
              style="width: 100%"
              :disabled="item.disabled"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="package-title margin-bottom-12">
            包裹备注（仅个人可见）：
          </div>
          <div>
            <el-input
              v-model="item.note"
              placeholder="请输入"
              :disabled="item.disabled"
            ></el-input>
          </div>
        </div>
      </div>
      <!-- 包裹右侧信息 -->
      <div class="package-right">
        <div class="tip margin-bottom-12" v-if="item.nowTax">
          <!-- <span class="tip1">当前税金：￥{{(item.nowTax).toFixed(2)}}</span> -->
          <span class="tip2" v-if="item.nowTax > taxExemptAmount"
            >预缴税金：￥{{
              item.nowTax > taxExemptAmount ? item.nowTax.toFixed(2) : 0
            }}</span
          >
        </div>
        <div class="tip">
          <span class="tip2 margin-bottom-12" v-if="item.showNumber"
            >单个包裹申报物品数量不得超过{{ sameMaxNum }}件<br
          /></span>

          <span class="tip2 margin-bottom-12" v-if="item.showPrice"
            >申报总价值不能超过RMB{{ maxPrice }}<br
          /></span>

          <span class="tip2 margin-bottom-12" v-if="item.showWeight"
            >申报物品重量不得超过{{ maxWeight }}KG</span
          >
        </div>
        <div class="package-title margin-bottom-20">包裹物品明细：</div>
        <!-- 包裹明细标题 -->
        <div class="package-title-box">
          <div class="package-select">请选择物品或输入关键字搜索</div>
          <div class="title-num">数量</div>
          <div class="title-num" style="width: 70px; margin-left: 8px">
            单价<span style="font-size: 12px">(RMB)</span>
          </div>
          <div class="title-num"></div>
        </div>
        <!-- 物品明细 -->
        <div
          class="package-content-box"
          v-for="(temp, j) in item.goodsList"
          :key="j"
        >
          <el-form
            :model="item.goodsList[j]"
            ref="packageForm"
            :rules="rules"
            :inline="true"
            class="demo-ruleForm"
          >
            <el-form-item class="content-select" prop="id">
              <el-select
                v-model="temp.id"
                placeholder="请选择"
                filterable
                clearable
                popper-class="my-select"
                @change="goodsChange(temp)"
                :disabled="item.disabled"
              >
                <el-option
                  v-for="item in options2"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <!-- 选择物品税率 -->
              <div
                v-if="temp.id"
                style="
                  position: absolute;
                  font-size: 12px;
                  opacity: 0.6;
                  text-align: left;
                  padding-left: 15px;
                  height: 24px;
                  line-height: 24px;
                "
              >
                税率：{{ temp.tax }}%
              </div>
            </el-form-item>
            <el-form-item class="content-num" prop="number">
              <el-input
                v-model="temp.number"
                :disabled="item.disabled"
                onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
              ></el-input>
            </el-form-item>
            <el-form-item class="content-num price" style="width: 70px">
              <el-input v-model="temp.price" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item class="content-num">
              <span
                class="el-icon-plus plus-btn"
                v-if="item.goodsList.length <= 10"
                @click="pushData(item.goodsList, item.disabled)"
              ></span>
              <!-- <span v-else class="else-btn" style="margin-right:12px"></span> -->
              <span
                class="el-icon-minus minus-btn"
                v-if="item.goodsList.length !== 1"
                @click="minusData(j, item.goodsList, item.disabled)"
              ></span>
              <!-- <span v-else class="else-btn"></span> -->
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- 删除包裹按钮 -->
      <span
        class="el-icon-close close-btn"
        v-if="packageList.length > 1"
        @click="delPackage(i, packageList)"
      ></span>
    </div>
  </div>
</template>
<script>
import {
  getServiceLineGoodsInfoByLineId,
  getServiceLineInfoById,
} from "@/api/order/order";
export default {
  name: "package",
  props: {
    packageList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    // 校验只能输入数字
    var numberReg = /^[1-9]+\d*$/;
    var validateNumber = (rule, value, callback) => {
      if (!value) {
        callback(new Error(" "));
      }
      if (!numberReg.test(value)) {
        callback(new Error(" "));
      } else {
        callback();
      }
    };
    return {
      rules: {
        id: { required: true, message: "请选择物品", trigger: "change" },
        number: { required: true, validator: validateNumber, trigger: "blur" },
        length: { required: true, message: "*长度", trigger: "blur" },
        width: { required: true, message: "*宽度", trigger: "blur" },
        height: { required: true, message: "*高度", trigger: "blur" },
        weight: { required: true, message: "*重量", trigger: "blur" },
      },
      // 服务路线名称
      serviceName: "",
      // 保险
      insurance: this.$store.state.insurance,
      // 备注
      remarks: "",
      // 物品数组
      tableData: [
        {
          goods: "",
          number: 1,
          unitPrice: "",
        },
      ],
      // 新增物品空模板
      newGoods: {
        // 物品名称
        id: "",
        // 物品数量
        number: 1,
        // 物品单价
        price: "",
        // 物品税率
        tax: "",
        // 物品重量
        suttle: "",
      },
      options: this.$store.state.InsuranceOptions,
      options2: [],

      // 包裹限制规则
      taxExemptAmount: 0, // 免税金额
      maxPrice: 0, // 商品最大价值
      maxWeight: 0, // 商品最大重量
      sameMaxNum: 0, // 物品最大数量

      // 判断是否超出规则
      showPrice: false, // 是否显示超出最大价值
      showWeight: false, // 是否显示超出最大重量
      showNumber: false, // 是否显示超出最大数量
    };
  },
  watch: {
    packageList: {
      handler(val, oldVal) {
        // 所有物品数组
        console.log(val.length, val, "发生变化", oldVal);
        for (let i = 0; i < val.length; i++) {
          // 收件人的包裹列表
          let packList = val[i].goodsList;
          // 当前税金默认值
          val[i].nowTax = 0;
          val[i].showWeight = false; // 是否显示超出最大重量
          val[i].showNumber = false; // 是否显示超出最大数量
          val[i].showPrice = false; // 是否显示超出最大价值
          val[i].goodsWeight = 0; // 当前包裹重量
          val[i].goodsPrice = 0; // 当前包裹的价值
          val[i].goodsNumber = 0; // 当前包裹物品数量和
          console.log(packList, "packList");
          for (let j = 0; j < packList.length; j++) {
            // 单个包裹信息
            let item = packList[j];
            // 当前税金计算结果
            val[i].nowTax +=
              Number(item.price) * Number(item.tax / 100) * Number(item.number);

            // 当前包裹数量和
            val[i].goodsNumber += Number(item.number);
            // 当前包裹价值计算结果
            val[i].goodsPrice += Number(item.price) * Number(item.number);
            // 当前包裹重量
            val[i].goodsWeight += Number(item.suttle) * Number(item.number);
          }
          // 包裹中物品数量大于限制数量
          console.log(
            val[i].goodsNumber,
            this.sameMaxNum,
            "判断物品数量是否超过"
          );
          console.log(val[i].goodsPrice, this.maxPrice, "判断物品价格是否超过");
          console.log(
            val[i].goodsWeight,
            this.maxWeight,
            "判断物品重量是否超过"
          );
          if (val[i].goodsNumber > this.sameMaxNum) {
            val[i].showNumber = true;
          } else {
            val[i].showNumber = false;
          }
          // 物品总价值大于最大限制
          if (val[i].goodsPrice > this.maxPrice) {
            val[i].showPrice = true;
          } else {
            val[i].showPrice = false;
          }
          // 物品总重量大于最大重量限制
          if (val[i].goodsWeight > this.maxWeight) {
            val[i].showWeight = true;
          } else {
            val[i].showWeight = false;
          }
        }
      },
      deep: true,
    },
  },
  created() {
    // this.getAllInsurance()
    this.getServiceLineGoodsInfoByLineId();

    // 获取当前路线的限制规则
    this.getServiceLineInfoById();
    let serviceName = sessionStorage.getItem("severName");
    if (serviceName != undefined) {
      this.serviceName = serviceName;
    }
  },
  methods: {
    // 获取当前路线的限制规则
    getServiceLineInfoById() {
      let upData = {
        id: this.$store.getters.getLineId,
      };
      getServiceLineInfoById(upData).then((res) => {
        if (res.data.code === 0) {
          // 免税金额
          this.taxExemptAmount = res.data.data.serviceLine.taxExemptAmount;
          // 最大金额限制
          this.maxPrice = res.data.data.serviceLine.maxPrice;
          // 最大重量限制
          this.maxWeight = res.data.data.serviceLine.maxWeight;
          // 物品最大数量
          this.sameMaxNum = res.data.data.serviceLine.sameMaxNum;
        }
      });
    },
    // 获取物品列表
    getServiceLineGoodsInfoByLineId() {
      let upData = {
        lineId: this.$store.getters.getLineId,
      };
      getServiceLineGoodsInfoByLineId(upData).then((res) => {
        if (res.data.code === 0) {
          this.options2 = res.data.data.list;
        }
      });
    },
    // 切换物品时
    goodsChange(temp) {
      let goodsList = this.options2;
      for (let i = 0; i < goodsList.length; i++) {
        if (goodsList[i].id === temp.id) {
          temp.price = goodsList[i].price;
          temp.tax = goodsList[i].tax;
          temp.suttle = goodsList[i].suttle;
        }
      }
    },
    // 添加物品
    pushData(rows, disabled) {
      if (disabled) {
        return;
      }
      let obj = { ...this.newGoods };
      rows.push(obj);
    },
    // 删除物品
    minusData(index, rows, disabled) {
      if (disabled) {
        return;
      }
      rows.splice(index, 1);
    },
    // 删除包裹
    delPackage(index, rows) {
      rows.splice(index, 1);
    },
  },
};
</script>
<style lang="less" scoped>
.package {
  background-color: #e5e4e7;
  border-radius: 8px;
  position: relative;
  display: flex;
  margin-bottom: 20px;
  // 包裹左侧样式
  .package-left {
    width: 354px;
    border-right: 1px solid #c7c7c7;
    .package-left-info {
      padding: 30px 24px 30px 30px;
      .package-content-size {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .size-item {
          .item-unit {
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #1e2b4f;
            line-height: 20px;
            margin-bottom: 4px;
          }
          .item-value {
            ::v-deep .el-input {
              width: 66px;
              .el-input__inner {
                height: 40px;
                border-radius: 10px;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
  // 包裹右侧样式
  .package-right {
    flex: 1;
    padding: 30px 20px 40px 24px;
    &::v-deep .el-select {
      width: 100%;
    }
    // 税金字体
    .tip {
      font-size: 16px;
      font-weight: 600;
    }
    .tip1 {
      color: #ff6400;
      margin-right: 20px;
    }
    .tip2 {
      color: #ff5353;
      display: inline-block;
    }
    .plus-btn,
    .minus-btn {
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      padding-left: 1px;
    }
    .plus-btn {
      background: @bg-color;
      color: #fff;
      margin-right: 12px;
      border: 1px solid @bg-color;
    }
    .minus-btn {
      background: #fff;
      border: 1px solid #e5e4e7;
      color: #d2d5dc;
    }
    .else-btn {
      width: 24px;
      display: inline-block;
      border: 1px solid transparent;
      font-size: 14px;
    }
    // 物品详情标题
    .package-title-box {
      display: flex;
      color: #1e2b4f;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 12px;
      .package-select {
        width: 240px;
        text-align: center;
        margin-right: 8px;
      }
      .title-num {
        width: 62px;
        margin-right: 8px;
        text-align: center;
      }
    }
    // 物品详情内容
    .package-content-box {
      .content-select {
        width: 240px;
        &::v-deep .el-form-item__content {
          width: 100%;
        }
      }
      .content-num {
        width: 62px;
      }
      .price {
        &::v-deep .el-input__inner {
          padding: 0 10px;
        }
      }
    }

    .package-table {
      background-color: #e5e4e7;
      border: none;
      &::before {
        background-color: transparent;
      }
    }
    &::v-deep .el-table tr,
    &::v-deep .el-table th.el-table__cell,
    &::v-deep .el-table td.el-table__cell {
      background-color: #e5e4e7;
      border: none;
      padding: 10px 0 0 0;
    }
  }
  // 标题样式
  .package-title {
    font-size: 16px;
    font-weight: 600;
    color: #1e2b4f;
  }
  // 文字内容样式
  .package-content {
    color: #1e2b4f;
    font-size: 14px;
    font-weight: 400;
  }
  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 20px;
    &:hover {
      color: @bg-color;
      cursor: pointer;
    }
  }
  .package-number {
    background: url("../../assets/img/fb-baoguo.png") no-repeat;
    background-size: 100% 100%;
    height: 36px;
    width: 106px;
    color: #ffffff;
    padding-left: 16px;
    line-height: 36px;
  }
  .margin-bottom-12 {
    margin-bottom: 12px;
  }
  .margin-bottom-20 {
    margin-bottom: 20px;
  }
  .margin-bottom-30 {
    margin-bottom: 30px;
  }
}
</style>
