import http from "@/utils/http";

const resquest = `${process.env.VUE_APP_BASE_URL}`;
const resquestExtent = `${process.env.VUE_APP_EXTEND_URL}`;
// const resquest = `http://192.168.1.100:7000/express`

// 获取服务分类
export function showLineTypeList(params) {
  return http.get(`${resquest}/user/serviceLine/showLineTypeList`, params);
}
// 获取服务下拉框
export function getAllServiceLine() {
  return http.get(`${resquest}/user/serviceLine/getAllServiceLine`);
}
// 根据重量目的地获取服务路线
export function queryServiceInfo(data) {
  return http.post(`${resquest}/user/customerPricePlan/queryServiceInfo`, data);
}

// 获取当前路线下的所有保险
export function getAllInsurance(params) {
  return http.get(`${resquest}/user/insurance/getAllInsurance`, params);
}

// 获取当前路线下的所有物品

export function getServiceLineGoodsInfoByLineId(params) {
  return http.get(`${resquest}/user/serviceLineGoods/getServiceLineGoodsInfoByLineId`, params);
}

// 新增订单，下单
export function addOrderPackage(data) {
  return http.post(`${resquest}/frontend/orderPackage/addOrderPackage`, data);
}

// 查询当前路线的限制
export function getServiceLineInfoById(params) {
  return http.get(`${resquest}/user/serviceLine/getServiceLineInfoById`, params);
}

// 查询用户的未支付订单
export function showShoppingCartList(params) {
  return http.get(`${resquest}/frontend/orderPackage/showShoppingCartList`, params);
}

// 删除未支付的订单
export function deleteOrderOfUnpay(data) {
  return http.post(`${resquest}/frontend/orderPackage/deleteOrderOfUnpay`, data);
}

// 查询订单详情
export function getOrderPackageDetail(params) {
  return http.get(`${resquest}/frontend/orderPackage/getOrderPackageDetail`, params);
}

// 修改订单
export function updateOrderPackagePeopleInfo(data) {
  return http.post(`${resquest}/frontend/orderPackage/updateOrderPackagePeopleInfo`, data);
}

// 支付接口

export function payOrderMoneyByRecharge(data) {
  return http.post(`${resquest}/user/payment/payOrderMoneyByRecharge`, data);
}
//余额支付
export function balancePayForOrderPackages(data) {
  return http.post(`${resquest}/user/payment/balancePayForOrderPackages`, data);
}
//支付成功回调
export function checkPaymentOrderStatus(params) {
  return http.get(`${resquest}/user/payment/checkPaymentOrderStatus`, params);
}
//获取物流状态下拉框列表
export function getAllShipmentLogisticsList() {
  return http.get(`${resquest}/frontend/shipment/getAllShipmentLogisticsList`);
}
//订单导出
export function downloadOrderInfoByIds() {
  return `${resquest}/frontend/orderPackage/downloadOrderInfoByIds`;
}
// 取消订单
export function cancelCustomerOrder(data) {
  return http.post(`${resquest}/frontend/orderPackage/cancelCustomerOrder`, data);
}


// 可邮寄列表的数据
export function showGoodsListToUser(data) {
  
  return http.post(`${resquest}/user/serviceLineGoods/showGoodsListToUser`, data);
}
// 可邮寄列表的数据
export function getServiceIntroduce(id) {
  return http.get(`${resquest}/user/serviceLine/echoDetail?id=` + id);
}

// 获取物品类别
export function getCargoCategory(data) {
  return http.post(`${resquest}/user/fr8/queryInfo`, data);
}

// 更新包裹状态
export function updatePackageStatus(data) {
  return http.post(`${resquestExtent}/api.php?c=package&f=update_package_ids`, data, 'application/x-www-form-urlencoded');
}

