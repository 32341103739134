<template>
  <div>
    <div class="address-form"
         v-for="(item,index) in addressArr"
         :key="index">
      <div class="form-header">
        <span class="number-index">{{index + 1}}</span>
        <span class="address-import" @click="$emit('importAddress',index)">从地址簿导入</span>
        
      </div>
      <el-form :inline="true"
               :rules="rules"
               :model="addressArr[index].consignee"
               ref="addressForm"
               class="demo-form-inline">
        <el-form-item class="form-item" prop="name">
          <MyInput :label="'姓名：'"
                   v-model="item.consignee.name"
                   :placeholder="'请输入真实姓名'"></MyInput>
        </el-form-item>
        <el-form-item class="form-item" prop="phone">
          <MyInput :label="'电话号码：'"
                   v-model="item.consignee.phone"
                   :placeholder="'请输入'"></MyInput>
        </el-form-item>
        
        <template v-if="!!serviceLine && (serviceLine.identityNum || serviceLine.identityPicture)">
          <el-form-item class="form-item"
                        style="margin-right:0px;" prop="identityNumber">
            <MyInput :label="'身份证号码：'"
                    v-model="item.consignee.identityNumber"
                    :placeholder="'请输入'"></MyInput>
          </el-form-item>
        </template>
        
        <div style="display: flex;">
          <el-form-item class="form-item-inline" prop="retained">
            <MyCascader :label="'城市：'"
                        v-model="item.consignee.retained"
                        @changeVal="changeRetained"
                        :placeholder="'请选择'"></MyCascader>
          </el-form-item>
          <el-form-item class="form-item-inline"
                        style="margin-right:0px;" prop="addressDetail">
            <MyInput :label="'具体地址：'"
                     v-model="item.consignee.addressDetail"
                     :placeholder="'请输入'"></MyInput>
          </el-form-item>
        </div>
      </el-form>
      <!-- 包裹组件 -->
      <PackageHK v-if="gateSign == 'HK'||gateSign=='SF'" :packageList="item.packageList" ref="package"></PackageHK>
      <Package v-else :packageList="item.packageList" ref="package"></Package>
      <div class="add-package" v-if="show"
           @click="addPackage(item.packageList)">添加包裹</div>
      <span class="el-icon-close close-btn"
            @click="closeAddress(index)"
            v-if="addressArr.length !== 1"></span>
    </div>
  </div>

</template>
<script>
import MyInput from "../input.vue"
import MyCascader from "../cascader.vue"
import Package from './package.vue'
import PackageHK from './packageHK.vue'
import { deepClone } from '@/utils/common'
export default {
  name: 'AddressForm',
  components: { MyInput, MyCascader, Package, PackageHK },
  props: {
    addressArr: {
      type: Array,
      default: () => {
        return []
      }
    },
    show:{
      type: Boolean,
      default: true
    },
    gateSign: {
      type: String,
      default: ""
    },
    serviceLine: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    // 校验只能输入中文
    // var nameReg = /^[\u4e00-\u9fa5]+$/
    // var validateName = (rule, value, callback) => {
    //   if(!value){
    //     callback(new Error('请输入姓名'))
    //   }
    //   if (!nameReg.test(value)) {
    //     callback(new Error('只可输入中文'))
    //   } else {
    //     callback()
    //   }
    // }
    var IdNumberReg = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/
    var validateIdNumber = (rule, value, callback) => {
      let needId = !!this.serviceLine && (this.serviceLine.identityNum || this.serviceLine.identityPicture);
      if(needId && !value){
        callback(new Error('请输入身份证号码'))
      }
      if (value && !IdNumberReg.test(value)) {
        callback(new Error('请输入正确的身份证号'))
      } else {
        callback()
      }
    }
    return {
      rules: {
        name:{ required: true, message: '请输入姓名', trigger: 'change' },
        phone:{ required: true, message: '请输入正确的电话号码', min:11, max:11, trigger: 'change'},
        identityNumber:{required: false,  validator:validateIdNumber, trigger: 'change'},
        retained:{ required: true, message: '请选择城市', trigger: 'change'},
        addressDetail:{ required: true, message: '请输入街道地址', trigger: 'change'},
      },
      formInline: {
        name: '',
        phone: '',
        idNumber: '',
        city: ["zujian", "data", "progress"],
        address: '',
      },
      obj: {
        //包裹大小
        length:this.$store.getters.getItemInfo.length,
        width:this.$store.getters.getItemInfo.width,
        height:this.$store.getters.getItemInfo.height,
        weight:this.$store.getters.getItemInfo.weight,
        // 保险服务
        insuranceId:  this.$store.state.insurance,
        // 包裹备注
        note: '',
        // 物品
        goodsList: [
          {
            // 物品名称(选项)
            id: '',
            // 物品数量
            number: 1,
            // 物品单价
            price: '',
            // 物品税率
            tax:'',
            // 物品重量
            suttle:'',
            // 物品名称(文本)
            name: "",
            // 物品类别
            type: "",
            sku: "",
          }
        ]
      }
    }
  },
  methods: {
    // 删除收件人 index 删除下标
    closeAddress (index) {
      this.$emit('closeAddress', index)
    },
    // 修改三级联动值
    changeRetained(val){
      console.log("val", val)
    },
    // 添加包裹
    addPackage (rows) {
      let obj = deepClone(this.obj)
      rows.push(obj)
    },
  }
}
</script>
<style lang="less" scoped>
.address-form {
  padding: 30px;
  background-color: #f7f7f7;
  border-radius: 8px;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 20px;
  .add-package {
    width: 88px;
    height: 36px;
    background: #6d5efe;
    border-radius: 22px;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    line-height: 36px;
    margin-top: 20px;
  }
  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 20px;
    &:hover {
      color: @bg-color;
      cursor: pointer;
    }
  }
  .form-header {
    margin-bottom: 40px;
    .number-index {
      width: 36px;
      height: 36px;
      background: #6d5efe;
      border-radius: 50%;
      color: #ffffff;
      display: inline-block;
      margin-right: 20px;
      text-align: center;
      line-height: 36px;
    }
  }
  // 从地址簿导入按钮
  .address-import {
    display: inline-block;
    width: 117px;
    height: 36px;
    background: #ffffff;
    border-radius: 22px;
    border: 1px solid @bg-color;
    color: @bg-color;
    text-align: center;
    line-height: 36px;
    font-size: 14px;
    cursor: pointer;
  }
  .form-item {
    width: 280px;
    margin-right: 20px;
    &::v-deep .el-form-item__content {
      width: 100%;
    }
  }
  .form-item-inline {
    flex: 1;
    &::v-deep .el-form-item__content {
      width: 100%;
    }
  }
}
</style>